import { SystemCssProperties } from '@styled-system/css';

export const headerStyles: SystemCssProperties = {
  height: ['50px', '70px'],
  backgroundColor: 'brand',
  paddingY: ['4xs', '3xs'],
  position: 'relative',
  display: 'flex',
  zIndex: 'header',
};
